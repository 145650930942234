import React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from '../templates/layout';
import MetaTags from '../components/MetaTags';

const TITLE = 'About';

const Page = ({ data, path }) => (
  <Layout>
    <MetaTags title="About" path={path} />
    <article className="max-w-5xl mx-auto px-4 md:px-8">
      <h1 className="page-heading font-extrabold">{TITLE}</h1>
      <section className="max-w-3xl mx-auto">
        <p>
          <strong>Serverless First</strong> is a one-person software consultancy
          focused on helping development teams successfully adopt serverless
          technologies.
        </p>
        <p>
          As our name suggests, we strongly believe that taking a
          serverless-first approach to software architecture will become the
          norm for most organisations building new products over the next few
          years.
        </p>
        <p className="italic">
          Serverless First is a brand name of Winter Wind Software Ltd which was
          formed in 2012.
        </p>
      </section>
      <section
        className="grid grid-cols-1 md:grid-cols-3 gap-8 my-20 border-t-2 border-b-2 border-primary-200 py-10"
        id="paul"
      >
        <div>
          <Img
            fluid={data.paulImage.childImageSharp.fluid}
            alt="Paul Swail"
            className="rounded-3xl"
          />
        </div>
        <div className="md:col-span-2">
          <h2 className="mb-0 text-3xl font-extrabold mt-0">Paul Swail</h2>
          <h3 className="text-primary-600 mt-0">Chief Cloud Architect</h3>
          <p>
            Paul has been delivering software solutions to customers across
            several industries using a wide range of technologies for almost 20
            years. He is a full-stack web developer by trade and is passionate
            about the cloud and web technology, but even more passionate about
            using software to solve real business problems.
          </p>
          <p>
            In addition to consulting work, Paul ran an AWS-based SaaS product
            for seven years between 2014–2021.
          </p>
          <p>
            Paul lives in Belfast, N.Ireland with his young family. When he’s
            not busy working, changing nappies or referring to himself in the
            third person, he enjoys playing and watching football, particularly
            the mighty Manchester United.
          </p>
          <p>
            You can find him online on{' '}
            <a href="https://twitter.com/paulswail">Twitter</a>,{' '}
            <a href="https://www.linkedin.com/in/paulswail">LinkedIn</a>, and{' '}
            <a href="https://github.com/paulswail">GitHub</a>.
          </p>
        </div>
      </section>
    </article>
  </Layout>
);

export const query = graphql`
  query {
    paulImage: file(relativePath: { eq: "paulswail_2021-09_a.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Page;
